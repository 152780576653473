/* TODO: Refactor the two wizards into a generic stepped component, the only changing variable is
.profile-onboarding-steps li {
  width: {number of steps}%;
}
*/

.profile-onboarding-steps {
  position: relative;
  overflow: hidden;
  counter-reset: wizard;
}
.profile-onboarding-steps li {
  position: relative;
  float: left;
  width: 33.333333333%;
  text-align: center;
  color: white;
  font-size: 16px;
}
.profile-onboarding-steps .current ~ li {
  color: #535d66;
}
.profile-onboarding-steps li:before {
  counter-increment: wizard;
  content: counter(wizard);
  display: block;
  color: black;
  background-color: #fff;
  border: 2px solid white;
  text-align: center;
  width: 36px;
  height: 36px;
  line-height: 32px; /* center the numbers, if the circle size is changed this needs updating  */
  border-radius: 36px;
  position: relative;
  left: 50%;
  margin-bottom: 18px;
  margin-left: -18px;
  z-index: 1;
}
.profile-current-step ~ li:before {
  background-color: #282d31 !important;
  color: #fff;
  border-color: #535d66;
}
.profile-onboarding-steps li + li:after {
  content: '';
  display: block;
  width: 100%;
  background-color: white;
  height: 2px;
  position: absolute;
  left: -50%;
  top: 18px;
  z-index: 0;
}

.profile-current-step ~ .profile-onboarding-steps li:before {
  background-color: #535d66;
}
.profile-onboarding-steps .profile-current-step ~ li:after {
  background-color: #535d66;
}
