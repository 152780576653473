@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

/* @config "../tailwind.config.js"; */

@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'Kallisto';
  src: url('../../shared_components/public/assets/rebrand-fonts/Kallisto-Bold.otf')
    format('opentype');
}

body {
  font-family: 'IBM Plex Sans', sans-serif;
  color: #191c1f;
}

body::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url('../../shared_components/public/assets/rebrand-icons/verification-check-filled.svg');
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Kallisto', sans-serif;
  font-weight: 400;
}

h2 {
  font-size: 32px;
  color: #fff;
}

/*
Onboarding steps
*/

.onboarding-steps {
  position: relative;
  overflow: hidden;
  counter-reset: wizard;
}
.onboarding-steps li {
  position: relative;
  float: left;
  width: 16.6666666667%;
  text-align: center;
  color: white;
  font-size: 18px;
}
.onboarding-steps .current ~ li {
  color: #535d66;
}
.onboarding-steps li:before {
  counter-increment: wizard;
  content: counter(wizard);
  display: block;
  color: black;
  background-color: #fff;
  border: 2px solid white;
  text-align: center;
  width: 36px;
  height: 36px;
  line-height: 32px; /* center the numbers, if the circle size is changed this needs updating  */
  border-radius: 36px;
  position: relative;
  left: 50%;
  margin-bottom: 18px;
  margin-left: -18px;
  z-index: 1;
}
.onboarding-current-step ~ li:before {
  background-color: #151719;
  color: #fff;
  border-color: #535d66;
}
.onboarding-steps li + li:after {
  content: '';
  display: block;
  width: 100%;
  background-color: white;
  height: 2px;
  position: absolute;
  left: -50%;
  top: 18px;
  z-index: 0;
}

.onboarding-current-step ~ .onboarding-steps li:before {
  background-color: #535d66;
}
.onboarding-steps .onboarding-current-step ~ li:after {
  background-color: #535d66;
}
.onboarding-steps .next-step:before {
  cursor: pointer;
}

/* Client Profile onboarding steps */
